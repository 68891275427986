<template>
    <div class="bar-container" :class="design">
        <div class="d-none d-lg-block">
            <div v-if="divider === 'top'" class="divider d-none d-md-block"></div>
            
            <div
                class="bar-item d-flex flex-row justify-content-between align-items-center"
                :class="design"
                :style="show ? 'margin-bottom: 0;' : ''">
                <slot name="content">
                </slot>
            </div>

            <div v-if="divider === 'bottom'" class="divider d-none d-md-block"></div>

            <div v-if="show" class="bar-list">
                <slot name="items">
                </slot>
            </div>
        </div>

        <div class="d-block d-lg-none h-100">
            <div
                class="bar-item d-flex flex-column text-start justify-content-between h-100"
                :class="design"
                :style="show ? 'margin-bottom: 0;' : ''">
                <slot name="content">
                </slot>
            </div>
            <div v-if="show" class="bar-list">
                <slot name="items">
                </slot>
            </div>
        </div>
    </div>
</template>

<script type="ts">

export default {
    props: {
        design: {
            type: String,
            default: 'small-bar',
        },
        show: {
            type: Boolean,
            default: false,
        },
        divider: {
            type: String,
            default: '',
        },
        headings: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        return {
            //
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";

.divider {
    margin: 0 2.375rem;
    border: 1px solid #dfdfdf;
}

.bar-container {
    &.extra-padding {
        padding-bottom: 30px;
    }
}

.bar-item {
    background: $white;
    min-height: 5.125rem;
    padding: 0 2.5rem 0 2.5rem;
    margin: 0.5rem 0;

    &.high-bar {
        height: 6.375rem;
    }

    &.no-margin {
        margin: 0;
    }

    &.small {
        min-height: unset;
    }
}

.bar-list {
    height: 100%;
    background: $white;
}

@media only screen and(max-width: 992px) {
    .bar-item {
        height: auto;
        padding: 20px 30px;
    }
}
</style>
