<template>
    <div class="accordion" :class="design">
        <div class="button d-flex flex-row justify-content-between align-items-center" @click="showRef = !showRef">
            <h4 class="flex-grow-1">
                {{label}}
            </h4>
            <component :is="showRef ? ChevronUp : ChevronDown" class="icon"></component>
        </div>

        <div v-show="showRef" class="content">
            <slot name="content">
            </slot>
        </div>
    </div>
</template>

<script type="ts">
import { ref } from 'vue';
import ChevronDown from '@/assets/icons/chevron-down.svg';
import ChevronUp from '@/assets/icons/chevron-up.svg';

export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        design: {
            type: String,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const showRef = ref(props.show);

        function buttonClick(e) {
            context.emit('buttonClick', e);
        }

        return {
            buttonClick,
            showRef,
            ChevronUp,
            ChevronDown,
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";
.button {
    cursor: pointer;
    padding: 11px 0 8px;
    h4 {
        font-family: 'DINPro';
        font-stretch: semi-condensed;
        font-size: 1.125em;
        font-weight: 600;
        line-height: 28px;
        text-transform: uppercase;
        letter-spacing: .075em;
    }
    .icon {
        width: 1rem;
    }
}

.accordion {
    border-bottom: 1px solid $kwast-red;

    &.cart {
        border-color: $black;

        .button {
            h4 {
                font-weight: 300;
                text-transform: capitalize;
            }
        }
    }
}

.content {
    padding-bottom: 10px;
}

</style>
