

export default {
    props: {
        margin: {
            type: String,
            default: '70px',
        },
    },
    setup() {
        return {};
    },
};
